import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import Loader from "../Shared/Loader/Loader";
import { Dropdown } from 'primereact/dropdown';
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import CommonConfig from '../../utils/constant';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import Toast from '../Shared/Toast/Toast';
import moment from 'moment';
import { FileUpload } from 'primereact/fileupload';
import { apiBase } from '../../utils/config';
import { MultiSelect } from 'primereact/multiselect';
import DeleteConfirmation from '../Dialogs/DeleteConfirmation';


const StudentProfile = () => {
    const navigate = useNavigate();
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [profilePath, setProfilePath] = useState(null);
    const [userName, setUserName] = useState('');
    const [panNo, setPanNo] = useState('');
    const [gstNo, setGSTNo] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [emailErr, setEmailErr] = useState(false);
    const [emailErrText, setEmailErrText] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [planName, setPlanName] = useState('');
    const [planFrom, setPlanFrom] = useState('');
    const [planTo, setPlanTo] = useState('');
    const fileUploadRef = useRef(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
    const [selectedNationality, setSelectedNationality] = useState(null);
    const [selectedReligion, setSelectedReligion] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [aadharNo, setAadharNo] = useState('');
    const [aadharOTP, setAadharOTP] = useState('');
    const [isAadharVerify, setIsAadharVerify] = useState(false);
    const [dob, setDob] = useState(null);
    const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
    const bloodGroupOptions = [{ label: 'A+', value: 'A+' }, { label: 'B+', value: 'B+' }];
    const nationalityOptions = [{ label: 'Indian', value: 'indian' }, { label: 'Other', value: 'other' }];
    const religionOptions = [{ label: 'Hindu', value: 'hindu' }, { label: 'Muslim', value: 'muslim' }];
    const categoryOptions = [{ label: 'General', value: 'general' }, { label: 'OBC', value: 'obc' }];
    const [age, setAge] = useState('');
    const [languageList, setLanguageList] = useState([]);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [previewProfilePath, setPreviewProfilePath] = useState('');
    const [isProfileAllowed, setIsProfileAllowed] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetLanguageList(loginData?._id);
        GetUserDetail(loginData?._id);
        getPersonDetail(loginData?._id);
    }, []);

    const GetUserDetail = async (userid) => {
        try {
            let data = {
                id: userid,
            }
            await api.post(APIConstant.path.getUserById, data).then(async response => {
                let res = response;
                if (res.success) {
                    setUserName(res?.data?.Name);
                    setMobileNumber(res?.data?.Phone);
                    setEmailAddress(res?.data?.Email);
                    setPlanName(res?.data?.PlanId);
                    setProfilePath(res?.data?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${res?.data?.ProfilePhotoUrl}` : res?.data?.CreatedBy ? `${apiBase}/UserProfilePic/${res?.data?.CreatedBy}` : null);
                    setIsProfileAllowed(res?.data?.IsAllowed);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetLanguageList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getLanguageList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setLanguageList(res.data.map(x => ({
                        ...x,
                        label: x?.LanguageName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: ID,
            };
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setPersonalDetailId(res?.data?._id);
                        setCurrentAddress(res?.data?.CurrentAddress);
                        setPinCode(res?.data?.PinCode);
                        setAadharNo(res?.data?.AadharNo);
                        setSelectedGender(res?.data?.Gender);
                        setDob(new Date(res?.data?.DOB) || '');
                        setAge(res?.data?.Age);
                        setSelectedNationality(res?.data?.Nationality);
                        setSelectedBloodGroup(res?.data?.BloodGroup);
                        setSelectedReligion(res?.data?.Religion);
                        setSelectedCategory(res?.data?.Category);
                        setLanguages(res?.data?.Language.split(',') || []);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const handleSave = async () => {
        await AddEditPersonalDetail();
        await AddEditUserDetail();
        await onReferesh();
    }

    const onReferesh = () => {
        GetUserDetail(securityUserID);
        getPersonDetail(securityUserID);
    }

    const AddEditPersonalDetail = async () => {
        try {
            let data = {
                id: personalDetailId,
                UserId: securityUserID,
                PinCode: pinCode,
                CurrentAddress: currentAddress,
                AadharNo: aadharNo,
                Gender: selectedGender,
                DOB: dob ? moment(dob).format("YYYY-MM-DD") : '',
                Age: age,
                Nationality: selectedNationality,
                BloodGroup: selectedBloodGroup,
                Religion: selectedReligion,
                Category: selectedCategory,
                Language: languages.length > 1 ? languages.join(',') : languages[0],
                CreatedBy: securityUserID,
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch(() => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setProfilePath(selectedFile);
                const fileURL = URL.createObjectURL(selectedFile);
                setPreviewProfilePath(fileURL);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'PAN') {
            setPanNo(alphanumericValue.toUpperCase().trim());
        } else if (type === 'GST') {
            setGSTNo(alphanumericValue);
        } else if (type === 'MobileNo') {
            setMobileNumber(numericValue);
        } else if (type === 'PinCode') {
            setPinCode(numericValue);
        }

    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        const maxLength = 50;
        if (value.length <= maxLength) {
            setEmailAddress(value);
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailErr(true);
            setEmailErrText('Please enter a valid email address.');
            // Toast.error({ message: 'Please enter a valid email address.' });
        } else {
            setEmailErr(false);
            setEmailErrText('');
        }
    };

    const AddEditUserDetail = async () => {
        try {
            const formData = new FormData();
            formData.append("id", securityUserID);
            formData.append("Name", userName);
            formData.append("Email", emailAddress);
            formData.append("Phone", mobileNumber);
            formData.append("CreatedBy", securityUserID);
            if (profilePath && profilePath.type) {
                formData.append("userProfilePic", profilePath);
            }
            Loader.show();
            await api.post(APIConstant.path.AddUpdateUser, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        Toast.success({ message: 'User details updated successfully' });
                    } else {
                        Toast.error({ message: res.message });
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    console.error(err);
                    Toast.error({ message: 'Failed to update user details' });
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Toast.error({ message: 'An error occurred' });
            Loader.hide();
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => setProfilePath("")}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    return (
        <div className="p-4">
            <h2 className="p-mb-4">User Profile</h2>
            <TabView>
                <TabPanel header="Basic details">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <div className="p-fluid">
                                <div className="p-field">
                                    <label>Name</label>
                                    <InputText value={userName} maxLength={20} onChange={(e) => setUserName(e.target.value)} />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="aadhar">Aadhar</label>
                                    <div className="p-inputgroup">
                                        <InputText id="aadhar" value={aadharNo} maxLength={12} onChange={(e) => handleChange(e, 'AadharNo')} />
                                        <Button label="Verify" />
                                    </div>
                                </div>
                                <div className="p-field">
                                    <label htmlFor="gender">Gender</label>
                                    <Dropdown id="gender" value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="dob">Date of Birth</label>
                                    <Calendar id="dob" value={dob} onChange={(e) => setDob(e.value)} showIcon dateFormat='dd/mm/yy' />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="age">Age</label>
                                    <InputText id="age" maxLength={2} value={age} onChange={(e) => handleChange(e, 'Age')} />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="nationality">Nationality</label>
                                    <Dropdown id="nationality" value={selectedNationality} options={nationalityOptions} onChange={(e) => setSelectedNationality(e.value)} placeholder="Select Nationality" />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="bloodGroup">Blood Group</label>
                                    <Dropdown id="bloodGroup" value={selectedBloodGroup} options={bloodGroupOptions} onChange={(e) => setSelectedBloodGroup(e.value)} placeholder="Select Blood Group" />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="religion">Religion</label>
                                    <Dropdown id="religion" value={selectedReligion} options={religionOptions} onChange={(e) => setSelectedReligion(e.value)} placeholder="Select Religion" />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="category">Category</label>
                                    <Dropdown id="category" value={selectedCategory} options={categoryOptions} onChange={(e) => setSelectedCategory(e.value)} placeholder="Select Category" />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="languages">Languages Spoken</label>
                                    <MultiSelect id="languages" value={languages} options={languageList} onChange={(e) => setLanguages(e.value)} placeholder="Select language" filter display='chip' />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-text-center" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <img
                                    src={!CommonConfig.isEmpty(previewProfilePath) ? previewProfilePath : !CommonConfig.isEmpty(profilePath) ? profilePath : "https://via.placeholder.com/150"}
                                    alt="logo"
                                    style={{ width: "150px", height: "150px", borderRadius: "8px" }}
                                />
                                {isProfileAllowed ?
                                    <FileUpload
                                        id="profilePath"
                                        mode="basic"
                                        name="profilePath"
                                        accept="image/*"
                                        maxFileSize={500000}
                                        chooseLabel="Profile"
                                        auto={false}
                                        onSelect={(e) => handleFileSelect(e)}
                                    />
                                    : null}
                                {isProfileAllowed && !CommonConfig.isEmpty(profilePath) && (
                                    <div
                                        className="icon-overlay"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setIsDeleteConfirmation(true)}
                                    >
                                        <i className="pi pi-trash" style={{ fontSize: "18px", color: "red" }}></i>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </TabPanel>
                <TabPanel header="Contact details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="fullName">Email Address</label>
                                <InputText id="fullName" value={emailAddress} onChange={(e) => handleEmailChange(e)} />
                                <span className="p-error block" style={{ color: 'red' }}>
                                    {emailErr ? emailErrText : null}
                                </span>
                            </div>
                            <div className="p-field">
                                <label htmlFor="contactNo">Contact Number</label>
                                <InputText id="contactNo" maxLength={10} value={mobileNumber} onChange={(e) => handleChange(e, 'MobileNo')} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="pinCode">Pin Code</label>
                                <InputText id="pinCode" maxLength={6} value={pinCode} onChange={(e) => handleChange(e, 'PinCode')} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="currentAddress">Current Address</label>
                                <InputTextarea id="currentAddress" value={currentAddress} type='textArea' rows={5} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Plan details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="planName">Plan Name</label>
                                <InputText id="planName" value={planName} disabled />
                            </div>
                            <div className="p-field">
                                <label htmlFor="planDuration">Plan Duration</label>
                                <div className="p-inputgroup">
                                    <label htmlFor="planFrom">From</label>
                                    <Calendar id="planFrom" value={planFrom} showIcon disabled dateFormat='dd/mm/yy' />
                                    <label htmlFor="planTo">To</label>
                                    <Calendar id="planTo" value={planTo} showIcon disabled dateFormat='dd/mm/yy' />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
            <div className="form-actions">
                <Button label="Back to Home" icon="pi pi-arrow-left" className="p-button-secondary p-mt-4" onClick={() => navigate('/')} />
                <Button label="Save" className="p-button-primary p-mt-4" onClick={() => handleSave()} />
            </div>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
};

export default StudentProfile;
