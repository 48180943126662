import React, { useState, useEffect } from 'react';
import Loader from "../Shared/Loader/Loader";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import CommonConfig from '../../utils/constant';
import SchoolProfile from './SchoolProfile';
import TeacherProfile from './TeacherProfile';
import StudentProfile from './StudentProfile';

const EditProfile = (props) => {
    const [loginUserType, setLoginUserType] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        GetUserDetail(loginData?._id);
    }, []);

    const GetUserDetail = async (userid) => {
        try {
            let data = {
                id: userid,
            }
            await api.post(APIConstant.path.getUserById, data).then(async response => {
                let res = response;
                if (res.success) {
                    setLoginUserType(res?.data?.UserType);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    return (
        <div className="p-4">
            {loginUserType === 'School' ? <SchoolProfile /> : loginUserType === 'Teacher' ? <TeacherProfile /> : loginUserType === 'Student' ? <StudentProfile /> : null}
        </div>
    );
};

export default EditProfile;
