import React from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import '../../../assets/css/teacherMaster.css';
import userImage from '../../../assets/images/UserImage.png';
import { useNavigate } from 'react-router-dom';
import { apiBase } from '../../../utils/config';

const ParentCard = ({ parent }) => {
    const navigate  = useNavigate();
    return (
        <div className="teacher-card p-mt-4">
            <img src={ parent?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${parent?.ProfilePhotoUrl}` : parent?.CreatedBy?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${parent?.CreatedBy?.ProfilePhotoUrl}` : userImage} alt='Profile' className="teacher-image" />
            <h4 className='p-m-0'>{parent?.Name}</h4>
            <p className='p-m-0'>{parent?.Status}</p>
            <div className="p-d-flex p-ai-center p-jc-center">
                <Button label="View"className='e-btn p-m-0' onClick={() => navigate('/viewEdit-parent-master',{ state: { userId: parent?._id, isEdit: false }} )} />
                <Button label="Edit" className='e-sction-btn p-ml-2 p-mr-0' onClick={() => navigate('/viewEdit-parent-master', { state: { userId: parent?._id, isEdit:true } })} />
            </div>
        </div>
    );
}

export default ParentCard;
