import React, { useState, useEffect } from 'react';
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import moment from 'moment';
import Toast from '../../Shared/Toast/Toast';
import { apiBase } from '../../../utils/config';
import { Dialog } from 'primereact/dialog';

const AddEditAcademicCalender = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [formData, setFormData] = useState({
        academicYearName: "",
        academicYearNameErr: false,
        academicYearNameErrText: '',
        startDate: null,
        startDateErr: false,
        startDateErrText: '',
        endDate: null,
        endDateErr: false,
        endDateErrText: '',
        classesApplicable: [],
        importURL: "",
        importFile: null,
    });
    const [classList, setClassList] = useState([]);

    const handleInputChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
        if (props?.configId) {
            setFormData((prev) => ({ ...prev, academicYearName: props?.masterData?.TitleName, startDate: props?.masterData?.StartDate ? new Date(props?.masterData?.StartDate) : null, endDate: props?.masterData?.EndDate ? new Date(props?.masterData?.EndDate) : null, classesApplicable: props?.masterData?.ApplicableClass?.split(',') || [], importURL: props?.masterData?.ImportUrl, importFile: props?.masterData?.ImportFile ? `${apiBase}/Documents/${props?.masterData?.ImportFile}` : null }));
        }
    }, []);

    const GetClassList = async (id) => {
        try {
          let data = {
            UserID: id,
          }
          await api.post(APIConstant.path.getClassList, data).then(async response => {
            let res = response;
            if (res.success) {
              setClassList(res.data.map((x) => ({
                ...x,
                value: x?._id,
                label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.Medium}-${x?.Board}`
              })));
    
              Loader.hide();
            }
          }).catch(err => {
            Loader.hide();
            console.log(err);
          });
        } catch (err) { console.log(err); }
      }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(formData?.academicYearName)) {
            formIsValid = false;
            setFormData((prev) => ({ ...prev, academicYearNameErr: true, academicYearNameErrText: 'Name is required' }));
        } else {
            setFormData((prev) => ({ ...prev, academicYearNameErr: false, academicYearNameErrText: '' }));
        }
        if (CommonConfig.isEmpty(formData?.startDate)) {
            formIsValid = false;
            setFormData((prev) => ({ ...prev, startDateErr: true, startDateErrText: 'Start date is required' }));
        } else {
            setFormData((prev) => ({ ...prev, startDateErr: false, startDateErrText: '' }));
        }
        if (CommonConfig.isEmpty(formData?.endDate)) {
            formIsValid = false;
            setFormData((prev) => ({ ...prev, endDateErr: true, endDateErrText: 'End Date is required' }));
        } else {
            setFormData((prev) => ({ ...prev, endDateErr: false, endDateErrText: '' }));
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
    }
    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                
                const dataForm = new FormData();
                dataForm.append("id", props?.masterData?._id ?? '');
                dataForm.append("TitleName", formData?.academicYearName);
                dataForm.append("StartDate", formData?.startDate ? moment(formData?.startDate).format("YYYY-MM-DD") : '');
                dataForm.append("EndDate", formData?.endDate ? moment(formData?.endDate).format("YYYY-MM-DD") : '');
                dataForm.append("ApplicableClass", formData?.classesApplicable?.length > 1 ? formData?.classesApplicable?.join(',') : formData?.classesApplicable[0]);
                dataForm.append("ImportUrl", formData?.importURL);
                dataForm.append("CreatedBy", securityUserID);
                if (formData?.importFile && formData?.importFile.type) {
                    dataForm.append("ImportFile", formData?.importFile);
                }
                Loader.show();
                
                await api
                    .post(APIConstant.path.AddUpdateAcademicCalander, dataForm, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Toast.error({ message: err?.data?.message });
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }else{
            Toast.error({ message: "bfsdjkfdskj" });
        }
    };

    const handleFileUpload = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setFormData((prev) => ({ ...prev, importFile: selectedFile }));
                const fileURL = URL.createObjectURL(selectedFile);
                setFormData((prev) => ({ ...prev, previewImportFile: fileURL }));
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG or PNG image.' });
            }
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label htmlFor="academicYearName">Academic Year Name</label>
                        <InputText
                            id="academicYearName"
                            value={formData?.academicYearName}
                            maxLength={15}
                            onChange={(e) => handleInputChange("academicYearName", e.target.value)}
                        />
                         <span className="error-msg" style={{ color: 'red' }}>
                            {formData?.academicYearNameErr ? formData?.academicYearNameErrText : null}
                        </span>
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label htmlFor="startDate">Start Date</label>
                        <Calendar
                            className='p-w-100'
                            id="startDate"
                            value={formData.startDate}
                            onChange={(e) => handleInputChange("startDate", e.value)}
                            showIcon
                        />
                         <span className="error-msg" style={{ color: 'red' }}>
                            {formData?.startDateErr ? formData?.startDateErrText : null}
                        </span>
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label htmlFor="endDate">End Date</label>
                        <Calendar
                            className='p-w-100'
                            id="endDate"
                            value={formData.endDate}
                            onChange={(e) => handleInputChange("endDate", e.value)}
                            showIcon
                        />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {formData?.endDateErr ? formData?.endDateErrText : null}
                        </span>
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label htmlFor="classesApplicable">Classes Applicable</label>
                        <MultiSelect
                            id="classesApplicable"
                            value={formData?.classesApplicable}
                            options={classList}
                            onChange={(e) => handleInputChange("classesApplicable", e.value)}
                            placeholder="Select Classes"
                            display="chip"
                        />
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label htmlFor="importURL">Import URL</label>
                        <InputText
                            id="importURL"
                            value={formData.importURL}
                            onChange={(e) => handleInputChange("importURL", e.target.value)}
                        />
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label htmlFor="importFile">Import File</label>
                        <FileUpload
                            name="importFile"
                            customUpload
                            auto
                            uploadHandler={handleFileUpload}
                            chooseLabel="Select File"
                        />
                    </div>
                </div>
            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
                <Button className='p-m-0 primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddUpdateTemplate()} />
            </div>
        </div>

    );
};

export default AddEditAcademicCalender;
