import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/apiClient';
import CommonConfig from '../../utils/constant';
import APIConstant from '../../utils/pathConstants';
import Toast from '../Shared/Toast/Toast';
import logo from '../../assets/icons/OBJECTS.svg';

const Sidebar = (props) => {
  const [sidebarMenus, setSidebarMenus] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [securityUserID, setSecurityUserID] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const user = CommonConfig.loginData();
    getUserMenu();
    setSecurityUserID(user?._id);
    setEmail(user?.Username);
    setName(user?.Name);

  }, []);

  const getUserMenu = async () => {
    try {
        const user = CommonConfig.loginData();
        const data = { UserId: user?._id, RoleId: user?.UserType };
        const res = await api.post(APIConstant.path.getUserMenu, data);
        if (res.success) {
          if(res.data.length){
          setSidebarMenus(res.data);
          }else{
            navigate('/edit-profile');
          }
        } else {
          setSidebarMenus([]);
        }
    } catch (err) {
      console.error('Error fetching user menu:', err);
    }
  };

  const manageSidebarWidth = () => {
    document.getElementById('sidebar-area').classList.toggle('sidebar-mini');
  };

  const handleMenuClick = (x, idx) => {
    if (x.Path) {
      navigate(x.Path);
    } else {
      let tempSidebarMenu = [...sidebarMenus];
      tempSidebarMenu[idx]["IsCollapseActive"] =
        tempSidebarMenu[idx].Children.filter((x) => x.IsCollapseActive == true)
          .length && tempSidebarMenu[idx]["IsCollapseActive"] == true
          ? tempSidebarMenu[idx]["IsCollapseActive"]
          : !tempSidebarMenu[idx].Children.filter(
            (x) => x.IsCollapseActive == true
          ).length && tempSidebarMenu[idx]["IsCollapseActive"] == true
            ? !tempSidebarMenu[idx]["IsCollapseActive"]
            : !tempSidebarMenu[idx]["IsCollapseActive"];

      setSidebarMenus([...tempSidebarMenu]);
    }
  };

  return (
    <div className="sidebar-container" id="sidebar-area">
      <div className="p-h-100 p-mx-2">
        <div
          onClick={manageSidebarWidth}
          className="sidebar-close cursor-pointer"
        >
          <i className="icon-back-arrow"></i>
        </div>
        <div className="sidebar-header">
          <div className='sidebar-header2'>
            <div className="sidebar-logo">
              <img src={logo} alt="Portlink Logo" />
            </div>
          </div>
        </div>
        <div className="sidebar-body">
          <ul className="parent-sidebar-options">
            {sidebarMenus.length ? (
              sidebarMenus.map((x, idx) => {
                return (
                  <li
                    className={`parent-sidebar-option p-flex-wrap ${x.IsCollapseActive ? "isParentActive" : ""
                      }`}
                    key={idx}
                    onClick={() => {
                      handleMenuClick(x, idx);
                    }}
                  >
                    <div
                      className={`p-d-flex p-ai-center p-jc-between p-w-100 parent-sidebar-content ${window.location &&
                          (window.location.pathname?.includes(x.Path) ||
                            (x.Children &&
                              x.Children.some((child) =>
                                child.Path?.includes(window.location.pathname)
                              )))
                          ? "active"
                          : ""
                        }`}
                    >
                      <div className="p-d-flex p-ai-center">
                        <i className="fas fa-info-circle"></i>
                        <span>{x.Title}</span>
                      </div>
                      {x?.Children.length > 0 && (
                        <i
                          className={`icon-chevron-down text-14 sidebar-tail-icon ${x.IsCollapseActive ? "active" : ""
                            }`}
                        ></i>
                      )}
                    </div>
                    {x?.IsCollapseActive && x?.Children && (
                      <ul className="child-sidebar-options">
                        {x?.Children.map((child, childIdx) => (
                          <li
                            className={`parent-sidebar-option p-flex-wrap ${window.location &&
                                (window.location.pathname?.includes(child.Path) ||
                                  (child.ChildSubMenus &&
                                    child.ChildSubMenus.some((subChild) =>
                                      subChild.Path?.includes(
                                        window.location.pathname
                                      )
                                    )))
                                ? "active"
                                : ""
                              }`}
                            key={childIdx}
                            onClick={() => {
                              if (child.Path) {
                                navigate(child.Path);
                              } else {
                                let tempSubMenus = [...x.Children];
                                tempSubMenus[childIdx]["IsCollapseActive"] =
                                  !tempSubMenus[childIdx]["IsCollapseActive"];
                                let tempSidebarMenus = [...sidebarMenus];
                                tempSidebarMenus[idx].Children = tempSubMenus;
                                setSidebarMenus([...tempSidebarMenus]);
                              }
                            }}
                          >
                            <div
                              className={`p-d-flex p-ai-center p-jc-between p-w-100 parent-sidebar-content ${window.location &&
                                  window.location.pathname.includes(child.Path)
                                  ? "active"
                                  : ""
                                }`}
                            >
                              <div className="p-d-flex p-ai-center">
                                <i className={`${child.Icon || "icon-default"} sidebar-icon`}></i>
                                <span>{child?.Title}</span>
                              </div>
                              {(child.Children && child?.Children.length > 0) && (
                                <i
                                  className={`icon-chevron-down text-14 sidebar-tail-icon ${child.IsCollapseActive ? "active" : ""
                                    }`}
                                ></i>
                              )}
                            </div>
                            {child.IsCollapseActive && child.ChildSubMenus && (
                              <ul className="sub-child-sidebar-options">
                                {child.ChildSubMenus.map(
                                  (subChild, subChildIdx) => (
                                    <li
                                      className={`child-sidebar-option ${window.location &&
                                          window.location.pathname.includes(
                                            subChild.Path
                                          )
                                          ? "active"
                                          : ""
                                        }`}
                                      key={subChildIdx}
                                      onClick={() => {
                                        if (subChild.Path) {
                                          props.navigate(subChild.Path);
                                        }
                                      }}
                                    >
                                      <div className="p-d-flex p-ai-center">
                                        <i className={`${subChild.Icon || "icon-default"} sidebar-icon`}></i>
                                        <span>{subChild.Title}</span>
                                      </div>
                                      {subChild?.Children.length > 0 && (
                                        <i
                                          className={`icon-chevron-down text-14 sidebar-tail-icon ${subChild.IsCollapseActive
                                              ? "active"
                                              : ""
                                            }`}
                                        ></i>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })
            ) : (
              <li>No menus available</li>
            )}
          </ul>
        </div>
      </div>
      <div className="sidebar-footer">
        <span className="sidebar-footer-text">
          Need help? Please contact us.
        </span>
      </div>
    </div>
  );
};

export default Sidebar;

