import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import userImage from '../../../assets/images/UserImage.png';
import '../../../assets/css/teacherMasterDetail.css';
import { Checkbox } from 'primereact/checkbox';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import { InputTextarea } from 'primereact/inputtextarea';
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { apiBase } from '../../../utils/config';
import { FileUpload } from 'primereact/fileupload';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import { Dialog } from 'primereact/dialog';

const ViewEditParentDetails = (props) => {
    const navigate = useNavigate();
    const [securityUserID, setSecurityUserID] = useState("");
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [fullName, setFullName] = useState("");
    const [parentId, setParentId] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [emailIdErrText, setEmailIdErrText] = useState('');
    const [emailIdErr, setEmailIdErr] = useState(false);
    const [aadharNo, setAadharNo] = useState('');
    const [selectedGender, setSelectedGender] = useState(null);
    const [aadharOTP, setAadharOTP] = useState('');
    const [isAadharVerify, setIsAadharVerify] = useState(false);
    const [dob, setDob] = useState('');
    const [age, setAge] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [qualification, setQualification] = useState('');
    const [occupation, setOccupation] = useState('');
    const [designation, setDesignation] = useState('');
    const [income, setIncome] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const genderOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
    const [childrens, setChildrens] = useState([]);
    const [childrensErr, setChildrensErr] = useState(false);
    const [childrensErrText, setChildrensErrText] = useState('');
    const [childrensList, setChildrensList] = useState([]);
    const [profilePath, setProfilePath] = useState(null);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [isRandom, setIsRandom] = useState(false);
    const [previewProfilePath, setPreviewProfilePath] = useState('');
    const [oldPermanentAddress, setOldPermanentAddress] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                setIsEdit(props?.location?.state?.isEdit)
                await GetChildrensList(loginData?._id);
                await getUserDetailById();
                await getPersonDetail();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        };
        fetchData();
    }, []);

    const onReferesh = async () => {
        await getUserDetailById();
        await getPersonDetail();
    }

    const getUserDetailById = async () => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.getUserById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setFullName(res?.data?.Name);
                        setMobileNumber(res?.data?.Phone);
                        setEmailAddress(res?.data?.Email);
                        setProfilePath(res?.data?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${res?.data?.ProfilePhotoUrl}` : res?.data?.CreatedBy ? `${apiBase}/UserProfilePic/${res?.data?.CreatedBy}` : null);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const GetChildrensList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getUser, data).then(async response => {
                let res = response;
                if (res.success) {
                    setChildrensList(res.data.filter((x) => x.UserType === 'Student')
                        .map((x) => ({
                            ...x,
                            label: x.Name,
                            value: x._id,
                        })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: props?.location?.state?.userId,
            };
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setPersonalDetailId(res?.data?._id);
                        setParentId(res?.data?.ProfileId);
                        setAadharNo(res?.data?.AadharNo);
                        setSelectedGender(res?.data?.Gender);
                        setDob(res?.data?.DOB ? new Date(res?.data?.DOB) : '');
                        setAge(res?.data?.Age);
                        setCurrentAddress(res?.data?.CurrentAddress);
                        setPermanentAddress(res?.data?.PermanentAddress);
                        setQualification(res?.data?.Qualification);
                        setOccupation(res?.data?.Occupation);
                        setDesignation(res?.data?.Designation);
                        setChildrens(res?.data?.TypeId ? res?.data?.TypeId.split(',') : []);
                        setIncome(res?.data?.Income)
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const saveDetails = async (e) => {
        await AddEditUserDetail();
        await AddEditPersonalDetail();
        onReferesh();
    }

    const AddEditUserDetail = async () => {
        try {
            const formData = new FormData();
            formData.append("id", props?.location?.state?.userId);
            formData.append("Name", fullName);
            formData.append("Email", emailAddress);
            formData.append("Phone", mobileNumber);
            if (profilePath && profilePath.type) {
                formData.append("userProfilePic", profilePath);
            }
            Loader.show();
            await api.post(APIConstant.path.AddUpdateUser, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        // Toast.success({ message: 'User details updated successfully' });
                    } else {
                        Toast.error({ message: res.message });
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    console.error(err);
                    Toast.error({ message: 'Failed to update user details' });
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Toast.error({ message: 'An error occurred' });
            Loader.hide();
        }
    };

    const AddEditPersonalDetail = async (e) => {
        try {
            let data = {
                id: personalDetailId,
                UserId: props?.location?.state?.userId,
                AadharNo: aadharNo,
                Gender: selectedGender,
                TypeId: childrens.length ? (childrens.length > 1 ? childrens.join(',') : childrens[0]) : '',
                Age: age,
                DOB: dob ? moment(dob).format("YYYY-MM-DD") : '',
                CurrentAddress: currentAddress,
                PermanentAddress: permanentAddress,
                Qualification: qualification,
                Occupation: occupation,
                Designation: designation,
                Income: income,
                CreatedBy: securityUserID,
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch((err) => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'FullName') {
            setFullName(alphanumericValue);
        } else if (type === 'MobileNo') {
            setMobileNumber(numericValue);
        } else if (type === 'AadharNo') {
            setAadharNo(numericValue);
        } else if (type === 'Qualification') {
            setQualification(alphanumericValue);
        } else if (type === 'Occupation') {
            setOccupation(alphanumericValue);
        } else if (type === 'Designation') {
            setDesignation(alphanumericValue);
        } else if (type === 'Income') {
            setIncome(numericValue);
        }
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        const maxLength = 50;
        if (value.length <= maxLength) {
            setEmailAddress(value);
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailIdErr(true);
            setEmailIdErrText('Please enter a valid email address.');
        } else {
            setEmailIdErr(false);
            setEmailIdErrText('');
        }
    };

    const calculateAge = (dob) => {
        if (!dob) return '';
        const today = new Date();
        const birthDate = new Date(dob);

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleDobChange = (e) => {
        const selectedDob = e.value;
        setDob(selectedDob);
        setAge(calculateAge(selectedDob));
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setProfilePath(selectedFile);
                const fileURL = URL.createObjectURL(selectedFile);
                setPreviewProfilePath(fileURL);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => setProfilePath('')}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const handleCheckBox = (e, type) => {
        setIsRandom(e.checked);
        if (e.checked) {
            setOldPermanentAddress(permanentAddress);
            setPermanentAddress(currentAddress);
        } else {
            setPermanentAddress(oldPermanentAddress);
            setOldPermanentAddress('');
        }
    };

    return (
        <div className="wayment-owner-dashboard e-weighment-dashoard">
            <div className="e-profile-header">
                {/* <img src={profilePath ?? userImage} alt="Profile" className="profile-pic" /> */}
                <div className="e-profile-box">
                    <div className="e-profile-main">
                        <img
                            src={!CommonConfig.isEmpty(previewProfilePath) ? previewProfilePath : !CommonConfig.isEmpty(profilePath) ? profilePath : userImage}
                            alt="logo"
                            style={{ width: "150px", height: "150px", borderRadius: "8px" }}
                            className="e-profile"
                        />
                        <div className='p-d-flex p-ai-center p-jc-between'>
                            {isEdit ?
                                <FileUpload
                                    id="profilePath"
                                    mode="basic"
                                    name="profilePath"
                                    accept="image/*"
                                    maxFileSize={500000}
                                    chooseLabel="Profile"
                                    auto={false}
                                    onSelect={(e) => handleFileSelect(e)}
                                />
                                : null}
                            {!CommonConfig.isEmpty(profilePath) && isEdit && (
                                <div
                                    className="icon-overlay"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setIsDeleteConfirmation(true)}
                                >
                                    <i className="pi pi-trash" style={{ fontSize: "18px", color: "red" }}></i>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="e-profile-info p-h-100">
                        <h2 className='p-text-justify p-mt-1'>{fullName}</h2>
                        <div className='profile-text-box'>
                            <p className='profile-text'>Parent ID - {parentId}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="form-section p-mb-0">
                <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="fullName">Full Name</label>
                        <InputText className='p-w-100' id="fullName" value={fullName} disabled={!isEdit} maxLength={20} onChange={(e) => handleChange(e, 'FullName')} />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="aadhar">Aadhar</label>
                        <div className="p-inputgroup p-d-flex p-ai-lg-baseline">
                            <InputText className='p-w-100 p-h-100' id="aadhar" disabled={!isEdit} maxLength={12} value={aadharNo} onChange={(e) => handleChange(e, 'AadharNo')} />
                            <Button className='p-m-0' label="Verify" disabled={!isEdit} />
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="emailId">Email ID</label>
                        <InputText className='p-w-100' id="emailId" value={emailAddress} disabled={!isEdit} onChange={(e) => handleEmailChange(e)} />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="mobileNo">Contact No.</label>
                        <InputText className='p-w-100' id="mobileNo" value={mobileNumber} disabled={!isEdit} onChange={(e) => handleChange(e, 'MobileNo')} />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="dob">Date of Birth</label>
                        <Calendar className='p-w-100' id="dob" value={dob} onChange={(e) => handleDobChange(e)} disabled={!isEdit} dateFormat='dd/mm/yy' />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="age">Age</label>
                        <InputText className='p-w-100' id="age" value={age} disabled />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="gender">Gender</label>
                        <Dropdown className='p-w-100' id="gender" disabled={!isEdit} value={selectedGender} options={genderOptions} onChange={(e) => setSelectedGender(e.value)} placeholder="Select Gender" />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="qualification">Qualification</label>
                        <InputText className='p-w-100' id="qualification" value={qualification} disabled={!isEdit} maxLength={30} onChange={(e) => handleChange(e, 'Qualification')} />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="occupation">Occupation</label>
                        <InputText className='p-w-100' id="occupation" value={occupation} disabled={!isEdit} maxLength={20} onChange={(e) => handleChange(e, 'Occupation')} />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="designation">Designation</label>
                        <InputText className='p-w-100' id="designation" value={designation} disabled={!isEdit} maxLength={20} onChange={(e) => handleChange(e, 'Designation')} />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="income">Annual Income</label>
                        <InputText className='p-w-100' id="income" value={income} disabled={!isEdit} onChange={(e) => handleChange(e, 'Income')} />
                    </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="currentAddress">Current Address</label>
                        <InputTextarea className='p-w-100' id="currentAddress" value={currentAddress} maxLength={100} type='textArea' rows={1} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} disabled={!isEdit} />
                    </div>
                    <div className="p-col-12 p-md-4">
                                <div className="p-d-flex p-ai-center p-mt-4">
                                    <Checkbox
                                        inputId="randomCheckbox"
                                        checked={isRandom}
                                        onChange={(e) => handleCheckBox(e)}
                                        disabled={!props?.location?.state?.isEdit}
                                    />
                                    <label className='p-ml-2' htmlFor="randomCheckbox">Permanent address same as current</label>
                                </div>
                            </div>
                    <div className="p-col-12 p-md-4">
                        <label className='p-d-block' htmlFor="permanentAddress">Permanent Address</label>
                        <InputTextarea className='p-w-100' id="permanentAddress" value={permanentAddress} maxLength={100} rows={1} cols={30} onChange={(e) => setPermanentAddress(e.target.value)} disabled={!isEdit} />
                    </div>
                    <div className="p-col-12 p-md-8">
                        <label className='p-d-block' htmlFor="children">Children</label>
                        <MultiSelect
                            id="children"
                            value={childrens}
                            disabled={!isEdit}
                            options={childrensList}
                            onChange={(e) => setChildrens(e.value)}
                            placeholder="Select Childrens"
                            filter
                            display="chip"
                            className='p-w-100'
                        />
                        <span className="error-msg" style={{ color: 'red' }}>
                            {childrensErr ? childrensErrText : null}
                        </span>
                    </div>
                    {/* <div className="p-d-flex p-ai-center p-jc-end">
                        {isEdit ?
                            <Button label="Save" icon="pi pi-check" onClick={() => saveDetails()} /> : null}
                        <Button label="Back" className="p-button-secondary" onClick={() => navigate('/parent-master')} />
                    </div> */}
                </div>
                <div className="p-d-flex p-ai-center p-jc-end">
                    {isEdit ?
                        <Button className="p-my-0 e-sction-btn p-mr-2" label="Save" onClick={() => saveDetails()} /> : null}
                    <Button label="Back" className="p-my-0 p-mx-0 e-border-btn" onClick={() => navigate('/parent-master')} />
                </div>
            </div>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
}

export default ViewEditParentDetails;
