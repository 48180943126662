import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { ColorPicker } from 'primereact/colorpicker';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import '../../../assets/css/idCardTemplateConfig.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { apiBase } from '../../../utils/config';
import { Dialog } from 'primereact/dialog';
import IDCardPreview from '../../Export/IDCardPreview';

const AddEditIdCardTemplate = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [templateWidth, setTemplateWidth] = useState(null);
    const [templateHeight, setTemplateHeight] = useState(null);
    const [fontTheme, setFontTheme] = useState(null);
    const [orientation, setOrientation] = useState('vertical');
    const [titleName, setTitleName] = useState('');
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState('');
    const [applySettingTo, setApplySettingTo] = useState('front');
    const [headerColor, setHeaderColor] = useState('#BD0000');
    const [footerColor, setFooterColor] = useState('#BD0000');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedBodyOptions, setSelectedBodyOptions] = useState([]);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [sigunatureImage, setSigunatureImage] = useState(null);
    const [authorityTitle, setAuthorityTitle] = useState(null);
    const [authorityTitleList, setAuthorityTitleList] = useState([
        { label: 'Principal', value: 'Principal' },
        { label: 'Class Tacher', value: 'Class Teacher' }
    ]);
    const [isNext, setIsNext] = useState(false);
    const fontThemes = [
        { label: 'Comic Sans', value: 'Comic Sans' },
        { label: 'Arial', value: 'Arial' },
        { label: 'Times New Roman', value: 'Times New Roman' }
    ];
    const options = [
        { label: 'School Logo', value: 'schoolLogo' },
        // { label: 'School Name', value: 'schoolName' },
        // { label: 'School Address', value: 'schoolAddress' },
        { label: 'Contact No.', value: 'contactNo' },
        // { label: 'Affiliation No.', value: 'affiliationNo' },
        // { label: 'Affiliation Board', value: 'affiliationBoard' },
        { label: 'School Email', value: 'schoolEmail' }
    ];
    const bodyoptionsList = [
        { label: 'Student Profile Pic', value: 'studentProfilePic' },
        { label: 'Student Name', value: 'studentName' },
        { label: 'Academic Year', value: 'academicYear' },
        // { label: 'Roll No.', value: 'rollNo' },
        { label: 'GR. NO.', value: 'grNo' },
        { label: 'Class & Section', value: 'classSection' },
        // { label: 'Mother Name', value: 'motherName' },
        { label: 'Father Name', value: 'fatherName' },
        { label: 'DOB', value: 'dob' },
        // { label: 'Mother Contact No.', value: 'motherPhoneNo' },
        { label: 'Father Contact No.', value: 'fatherContactNo' },
        { label: 'Address', value: 'address' },
        // { label: 'Barcode', value: 'barCode' },
        // { label: 'QR Code', value: 'qrCode' }
    ];
    const [selectedProfileOptions, setSelectedProfileOptions] = useState('');

    const profileOptionsList = [
        { label: 'Round', value: 'round' },
        { label: 'Rectangle', value: 'rectangle' },
        // { label: 'Academic Year', value: 'academicYear'},
    ];

    const [previewProfilePath, setPreviewProfilePath] = useState('');
    const [previewSignPath, setPreviewSignPath] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props?.configId) {
            setTitleName(props?.masterData?.TitleName);
            setTitleName(props?.masterData?.TitleName);
            setApplySettingTo(props?.masterData?.ApplyThisIn);
            setTemplateWidth(props?.masterData?.TemplateWidth);
            setTemplateHeight(props?.masterData?.TemplateHeight);
            setFontTheme(props?.masterData?.FontTheme);
            setOrientation(props?.masterData?.Orientation);
            setHeaderColor(props?.masterData?.HeaderColor)
            setSelectedOptions(props?.masterData?.HeaderSection?.split(',') || []);
            setSelectedBodyOptions(props?.masterData?.BodySection?.split(',') || []);
            setSelectedProfileOptions(props?.masterData?.ProfileStyle);
            setFooterColor(props?.masterData?.FooterColor);
            setSelectedProfileOptions(props?.masterData?.ProfileStyle);
            setAuthorityTitle(props?.masterData?.AuthorityTitle);
            setBackgroundImage(props?.masterData?.BackGroundImage ? `${apiBase}/TemplateBackGround/${props?.masterData?.BackGroundImage}` : null);
            setSigunatureImage(props?.masterData?.SignImage ? `${apiBase}/TemplateSign/${props?.masterData?.SignImage}` : null);
        }
    }, []);


    const togglePreview = () => {
        setIsPreviewOpen(!isPreviewOpen);
    };

    const handleCheckboxChange = (e) => {
        const selectedValues = e.checked
            ? [...selectedOptions, e.value]
            : selectedOptions.filter(item => item !== e.value);
        setSelectedOptions(selectedValues);
    };

    const handleCheckBodyboxChange = (e) => {
        const selectedBodyValues = e.checked
            ? [...selectedBodyOptions, e.value]
            : selectedBodyOptions.filter(item => item !== e.value);
        setSelectedBodyOptions(selectedBodyValues);
    };


    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
    }
    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                const formData = new FormData();
                formData.append("id", props?.masterData?._id ?? '');
                formData.append("TitleName", titleName);
                formData.append("ApplyThisIn", applySettingTo);
                formData.append("TemplateWidth", templateWidth);
                formData.append("TemplateHeight", templateHeight);
                formData.append("FontTheme", fontTheme);
                formData.append("Orientation", orientation);
                formData.append("HeaderColor", headerColor);
                formData.append("HeaderSection", selectedOptions.length > 1 ? selectedOptions.join(',') : selectedOptions[0]);
                formData.append("BodySection", selectedBodyOptions.length > 1 ? selectedBodyOptions.join(',') : selectedBodyOptions[0],);
                formData.append("FooterColor", footerColor);
                formData.append("ProfileStyle", selectedProfileOptions);
                formData.append("AuthorityTitle", authorityTitle);
                formData.append("CreatedBy", securityUserID);
                if (backgroundImage && backgroundImage.type) {
                    formData.append("BackGroundImage", backgroundImage);
                }
                if (sigunatureImage && sigunatureImage.type) {
                    formData.append("SignImage", sigunatureImage);
                }
                Loader.show();
                await api
                    .post(APIConstant.path.AddUpdateIdCardConfig, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Toast.error({ message: err?.data?.message });
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleBackGroundImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setBackgroundImage(selectedFile);
                const fileURL = URL.createObjectURL(selectedFile);
                setPreviewProfilePath(fileURL);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG or PNG image.' });
            }
        }
    };

    const handleSigunatureImage = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                setSigunatureImage(selectedFile);
                const fileURL = URL.createObjectURL(selectedFile);
                setPreviewSignPath(fileURL);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG or PNG image.' });
            }
        }
    };

    return (
        <div className="template-config-container p-py-3 p-px-3">
            <div className="id-card-preview-container" onClick={togglePreview} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <div style={{ width: '150px', height: '100px', overflow: 'hidden', border: '1px solid #ccc', borderRadius: '8px' }}>
                    <IDCardPreview {...props} />
                </div>
            </div>
            {!isNext ? (
                <>
                    <h2 className='p-m-0 p-d-flex p-pb-1'>Title</h2>
                    <hr className='p-m-0' />
                    <div className="title-settings p-grid p-align-center">
                        <div className="p-col-6 p-md-6">
                            <label htmlFor="titleName">Title Name</label>
                            <FloatLabel>
                                <InputText
                                    className='p-mt-2'
                                    id="titleName"
                                    value={titleName}
                                    onChange={(e) => setTitleName(e.target.value)}
                                />
                            </FloatLabel>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="p-mb-2">Apply this setting to</label>
                            <div className="p-d-flex p-ai-center p-mt-2">
                                <div className="p-field-radiobutton p-mr-3">
                                    <RadioButton
                                        inputId="front"
                                        name="applySettingTo"
                                        value="front"
                                        checked={applySettingTo === 'front'}
                                        onChange={(e) => setApplySettingTo(e.value)}
                                    />
                                    <label htmlFor="front">Front</label>
                                </div>
                                <div className="p-field-radiobutton">
                                    <RadioButton
                                        inputId="back"
                                        name="applySettingTo"
                                        value="back"
                                        checked={applySettingTo === 'back'}
                                        onChange={(e) => setApplySettingTo(e.value)}
                                    />
                                    <label htmlFor="back">Back</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="layout-settings">
                        <h2 className='p-m-0 p-d-flex p-pb-1'>Layout Settings</h2>
                        <hr className='p-m-0' />
                        <div className="p-grid p-fluid p-mt-2">
                            <div className="p-col-6 p-md-6">
                                <label htmlFor="templateWidth">ID Template Width (cm)</label>
                                <FloatLabel>
                                    <InputNumber id="templateWidth" value={templateWidth} onValueChange={(e) => setTemplateWidth(e.value)} />
                                </FloatLabel>
                            </div>
                            <div className="p-col-6 p-md-6">
                                <label htmlFor="templateHeight">ID Template Height (cm)</label>
                                <FloatLabel>
                                    <InputNumber id="templateHeight" value={templateHeight} onValueChange={(e) => setTemplateHeight(e.value)} />
                                </FloatLabel>
                            </div>
                            <div className="p-col-6 p-md-6">
                                <label htmlFor="fontTheme">Font Theme</label>
                                <FloatLabel>
                                    <Dropdown id="fontTheme" className='p-pl-2' value={fontTheme} options={fontThemes} onChange={(e) => setFontTheme(e.value)} placeholder="Select a font theme" />
                                </FloatLabel>
                            </div>
                            <div className="p-col-6 p-md-6 orientation-container">
                                <label>Orientation</label>
                                <div className="orientation-radio-buttons">
                                    <RadioButton inputId="vertical" name="orientation" value="vertical" checked={orientation === 'vertical'} onChange={(e) => setOrientation(e.value)} />
                                    <label htmlFor="vertical">Vertical</label>
                                    <RadioButton inputId="horizontal" name="orientation" value="horizontal" checked={orientation === 'horizontal'} onChange={(e) => setOrientation(e.value)} />
                                    <label htmlFor="horizontal">Horizontal</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-section p-grid p-ai-center">
                        <div className='p-grid p-w-100'>
                            <div className="p-col-12 p-md-12 p-d-flex p-ai-center p-jc-between">
                                <h2 className="p-m-0">Header Section</h2>
                            </div>
                            <hr className='p-m-0 p-w-100' />
                            <div className="p-col-12 p-md-6 p-d-flex p-ai-center p-jc-between">
                                <label htmlFor="headerColor">Header Color</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-d-flex p-ai-baseline">
                                    <ColorPicker
                                        value={headerColor}
                                        onChange={(e) => setHeaderColor(e.value)}
                                        // style={{ width: '100%' }}
                                        className='p-mr-2'
                                    />
                                    <input
                                        type="text"
                                        value={`${headerColor.toUpperCase()}`}
                                        readOnly
                                        style={{
                                            width: '100%',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            marginTop: '0.5rem',
                                            padding: '0.5rem',
                                            textAlign: 'center'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-6 checkbox-group p-d-flex p-flex-wrap">
                            {options.map((option) => (
                                <div key={option.value} className="p-field-checkbox p-mr-2 p-mb-2">
                                    <Checkbox
                                        inputId={option.value}
                                        value={option.value}
                                        onChange={handleCheckboxChange}
                                        checked={selectedOptions.includes(option.value)}
                                    />
                                    <label htmlFor={option.value}>{option.label}</label>
                                </div>
                            ))}
                        </div>

                        <div className="p-col-12 p-md-6">
                            <div className="p-text-center">
                                <img
                                    src={!CommonConfig.isEmpty(previewProfilePath) ? previewProfilePath : !CommonConfig.isEmpty(backgroundImage) ? backgroundImage : "https://via.placeholder.com/150"}
                                    alt="backGroundImage"
                                    className="p-mb-3"
                                    style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                                />
                                <FileUpload
                                    id="backgroundImage"
                                    mode="basic"
                                    name="backgroundImage"
                                    accept="image/*"
                                    maxFileSize={500000}
                                    chooseLabel="Select Background Image"
                                    auto={false}
                                    onSelect={(e) => handleBackGroundImage(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <hr className='p-m-0' />
                    <Button label="Next" icon="pi pi-arrow-right" onClick={(e) => setIsNext(true)} />
                </>) : (
                <>
                    <div className="header-section p-ai-center">
                        <h2 className="p-m-0 p-d-flex p-pb-2">Body Section</h2>
                        <hr className='p-m-0' />
                        <div className='p-grid'>
                            <div className="p-col-12 p-md-9 checkbox-group p-d-flex p-flex-wrap">
                                {bodyoptionsList.map((option) => (
                                    <div key={option.value} className="p-field-checkbox e-field-checkbox p-mr-2 p-mb-2">
                                        <Checkbox
                                            inputId={option.value}
                                            value={option.value}
                                            onChange={handleCheckBodyboxChange}
                                            checked={selectedBodyOptions.includes(option.value)}
                                        />
                                        <label htmlFor={option.value}>{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="header-section p-ai-center">
                        <h2 className="p-m-0 p-d-flex p-pb-2">Profile Style</h2>
                        <hr className='p-m-0' />
                        <div className='p-grid'>
                            <div className="p-col-12 p-md-9 checkbox-group p-d-flex p-flex-wrap">
                                {profileOptionsList.map((option, idx) => (
                                    <div key={option?.value} className="p-field-checkbox e-field-checkbox p-mr-2 p-mb-2">
                                        <Checkbox
                                            inputId={option?.value}
                                            value={option?.value}
                                            onChange={(e) => setSelectedProfileOptions(e.value)}
                                            checked={selectedProfileOptions === option?.value}
                                        />
                                        <label htmlFor={option?.value}>{option?.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    
                    <div className="header-section  p-ai-center">
                        <h2 className="p-m-0 p-d-flex p-pb-2">Footer Section</h2>
                        <hr className='p-m-0' />
                        <div className='p-grid'>
                            <div className="p-col-12 p-md-6 p-mt-3">
                                <div className="p-d-flex p-ai-baseline p-mt-1">
                                    <label htmlFor="footerColor" style={{ whiteSpace: 'nowrap' }}>Footer Color</label>
                                    <ColorPicker
                                        value={footerColor}
                                        onChange={(e) => setFooterColor(e.value)}
                                        // style={{ width: '100%' }}
                                        className='p-w-100 p-ml-3'
                                    />
                                    <input
                                        type="text"
                                        value={`${footerColor.toUpperCase()}`}
                                        readOnly
                                        style={{
                                            width: '100%',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            marginTop: '0.5rem',
                                            padding: '0.5rem',
                                            textAlign: 'center'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-mt-3">
                                <FloatLabel>
                                    <Dropdown id="authorityTitle" className='p-w-100' value={authorityTitle} options={authorityTitleList} onChange={(e) => setAuthorityTitle(e.value)} placeholder="Select Authority Title" />
                                    <label className='p-pl-2' htmlFor="authorityTitle">Authority Title</label>
                                </FloatLabel>
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="p-col-12 p-md-12 p-md-4">
                                <div className="p-text-center">
                                    <img
                                        src={!CommonConfig.isEmpty(previewSignPath) ? previewSignPath : !CommonConfig.isEmpty(sigunatureImage) ? sigunatureImage : "https://via.placeholder.com/150"}
                                        alt="sigunatureImage"
                                        className="p-mb-3"
                                        style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                                    />
                                    <FileUpload
                                        id="signImage"
                                        mode="basic"
                                        name="signImage"
                                        accept="image/*"
                                        maxFileSize={500000}
                                        chooseLabel="Select Sigunature Image"
                                        auto={false}
                                        onSelect={(e) => handleSigunatureImage(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='dialog-footer1 p-d-flex p-ai-center p-jc-center'>
                        <Button className='p-m-0 primary-btn1 p-m-1' label="Back" icon="pi pi-arrow-left" onClick={(e) => setIsNext(false)} />
                        <Button className='p-m-0 primary-btn' label="Save" onClick={(e) => AddUpdateTemplate()} />
                    </div>
                </>)
            }
            <Dialog
                header="ID Card Preview"
                visible={isPreviewOpen}
                // style={{ width: '80vw' }}
                onHide={togglePreview}
                dismissableMask
            >
                <div style={{ textAlign: 'center' }}>
                    <IDCardPreview {...props} />
                </div>
            </Dialog>
        </div >
    );
};

export default AddEditIdCardTemplate;
