
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { Dialog } from 'primereact/dialog';
import AddSectionMasterDialog from '../SectionMaster/AddSectionMaster';
import { ColorPicker } from 'primereact/colorpicker';

const AddHouseMasterDialog = (props) => {
    const [houseName, setHouseName] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [houseNameErrText, setHouseNameErrText] = useState('');
    const [houseNameErr, setHouseNameErr] = useState(false);
    const [color, setColor] = useState('#8D0000');
    const [colorErr, setColorErr] = useState(false);
    const [colorErrText, setColorErrText] = useState('');
    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props.ID) {
            setHouseName(props?.masterData?.HouseName);
            setColor(props?.masterData?.ColorCode);
        }

    }, []);

    const onCancel = () => {
        props.onCancel();
        setHouseName('');
        setColor('');
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(houseName)) {
            formIsValid = false;
            setHouseNameErr(true);
            setHouseNameErrText("House Name is required");
        } else {
            setHouseNameErr(false);
            setHouseNameErrText("");
        }
        if (CommonConfig.isEmpty(color)) {
            formIsValid = false;
            setColorErr(true);
            setColorErrText("Color is required");
        } else {
            setColorErr(false);
            setColorErrText("");
        }
        return formIsValid;
    };

    const AddHouseMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    HouseName: houseName,
                    ColorCode: color,
                    CreatedBy: securityUserID,
                };

                await api
                    .post(APIConstant.path.AddUpdateHouseMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        setHouseName(alphanumericValue);
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label className='' htmlFor="houseName">House Name</label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="houseName"
                                type="text"
                                maxLength={20}
                                placeholder="House Name"
                                value={houseName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {houseNameErr ? houseNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-3 p-pt-2">
                    <div className="p-w-100">
                        <div className="custom-inputs">
                            <label htmlFor="ColorCode" className="mr-2">Color Code</label>
                            <ColorPicker
                                value={color}
                                onChange={(e) => setColor(e.value)}
                                style={{ marginTop: '0.5rem' }}
                                inline
                            />
                            <input
                                type="text"
                                value={`#${color.toUpperCase()}`}
                                readOnly
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    marginTop: '0.5rem',
                                    padding: '0.5rem',
                                    textAlign: 'center'
                                }}
                            />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {colorErr ? colorErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
                <Button className='p-m-0 primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddHouseMaster()} />
            </div>
        </div>
    );
};

export default AddHouseMasterDialog;
