import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import '../src/assets/scss/_primeflex.scss';
import '../src/assets/scss/_primereact.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import "primeicons/primeicons.css"; 
import '../src/assets/scss/Style.scss';
import Router from './Router';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Router />
  </BrowserRouter>
);

reportWebVitals();
