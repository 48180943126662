import React from 'react';
import { apiBase } from '../../utils/config';
import moment from 'moment';
import UserImage from '../../assets/svgs/portlink-logo.svg';

const IDCardPreview = (props) => {
    const pdfBlocks = [];
    pdfBlocks.push(
        <div className='main-body'>
            <div className="p-position-relative id-card-pdf">
                <div className="id-card-main" style={{
                    "--dynamic-gradient-end": '',
                }}>
                    <div className="card-parent">
                        <div className="info-box">
                            <div className="header-logo">
                                <img
                                    src={UserImage}
                                    // alt="School Logo"
                                    className="logo"
                                />
                            </div>
                            <div className="Info-box">
                                <div className="background">
                                    <img src={
                                        UserImage
                                    } />
                                </div>
                                <div className="current-year">
                                    <p className="year-text">2024</p>
                                    <p className="year-text">-</p>
                                    <p className="year-text">2025</p>
                                </div>

                                <div className="info-main">
                                    <div className="p-d-flex p-ai-center">
                                        <div className="profile-box">
                                            <img
                                                src={UserImage}
                                                // alt="Profile"
                                                className="profile"
                                            />
                                        </div>

                                        <p className="standerd-text">classSectionName</p>
                                    </div>

                                    <div className="name">
                                        <p className="name-text">Student Name</p>
                                    </div>

                                    <div className="inner-info">
                                        <p className="inner-info-text">Father</p>
                                        <p className="inner-info-subtext">
                                            <span className="dot">:</span>FatherName
                                        </p>
                                    </div>
                                    <div className="inner-info">
                                        <p className="inner-info-text">G.R</p>
                                        <p className="inner-info-subtext">
                                            <span className="dot">:</span>GRNo
                                        </p>
                                    </div>
                                    <div className="inner-info">
                                        <p className="inner-info-text">D.O.B.</p>
                                        <p className="inner-info-subtext">
                                            <span className="dot">:</span>DOB
                                        </p>
                                    </div>
                                    <div className="inner-info">
                                        <p className="inner-info-text">Contact</p>
                                        <p className="inner-info-subtext">
                                            <span className="dot">:</span>FatherPhoneNo
                                        </p>
                                    </div>
                                    <div className="inner-info">
                                        <p className="inner-info-text">Address</p>
                                        <p className="inner-info-subtext font-size">
                                            <span className="dot">:</span>Address
                                        </p>
                                    </div>
                                </div>

                                <div className="p-signature p-d-flex p-flex-column p-jc-end p-py-1">
                                    <img src={UserImage} className='footer-sign' />
                                    <p className="inner-info-text p-w-md-auto p-mr-2">AuthorityTitle</p>
                                </div>
                            </div>

                            <div className="footer-text-box">
                                <p className="footer-text">
                                    SchoolPhone, SchoolEmail
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    return pdfBlocks;
};

export default IDCardPreview;
