import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import CommonConfig from '../../../utils/constant';
import Loader from "../../Shared/Loader/Loader";
import Toast from '../../Shared/Toast/Toast';
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import { Dialog } from 'primereact/dialog';

const StudentList = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [visible, setVisible] = useState(false);
    const [studentsList, setStudentsList] = useState([]);
    const [selectedHouse, setSelectedHouse] = useState([]);
    const [isRandom, setIsRandom] = useState(false);
    const [byStudentID, setByStudentID] = useState(false);
    const [byNameWise, setByNameWise] = useState(false);

    const [houseList, setHouseList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        setStudentsList(props?.studentsList);
        GetHouseList(loginData?._id);
    }, []);

    const resetForm = () => {
        setSelectedHouse([]);
        setIsRandom(false);
        setByStudentID(false);
        setByNameWise(false);
    };

    const onCancel = () => {
        setVisible(false);
        resetForm();
    };

    const GetHouseList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getHouseList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setHouseList(res.data.map(x => ({
                        ...x,
                        label: x?.HouseName,
                        value: x._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const assignhouse = async (e) => {
        if (selectedHouse.length) {
            try {
                Loader.show();
                for (let student of studentsList) {
                    let houseValue;

                    if (selectedHouse.length === 1) {
                        houseValue = selectedHouse[0];
                    } else {
                        if (student?.Gender === 'male') {
                            houseValue = selectedHouse[Math.floor(Math.random() * Math.ceil(selectedHouse.length / 2))];
                        } else if (student?.Gender === 'female') {
                            houseValue = selectedHouse[Math.floor(Math.random() * Math.ceil(selectedHouse.length / 2)) + Math.floor(selectedHouse.length / 2)];
                        } else {
                            houseValue = selectedHouse[Math.floor(Math.random() * selectedHouse.length)];
                        }
                    }
                    let data = {
                        id: student?.AdmissionId,
                        UserId: student?.id,
                        House: houseValue,
                    };
                    await api
                        .post(APIConstant.path.AddAdmissionDetail, data)
                        .then((response) => {
                            let res = response;
                            if (res.success) {
                                Toast.success({ message: res.message });
                            } else {
                                Toast.error({ message: res.message });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            Toast.error({ message: 'Error assigning house' });
                        });
                }

                Loader.hide();
                onCancel();
            } catch (err) {
                console.log(err);
                Loader.hide();
                Toast.error({ message: 'Error during house assignment' });
            }
        } else {
            Toast.error({ message: 'Please select house' });
        }
    };

    const saveStudentRollNo = async (e) => {
        try {
            Loader.show();
            for (let student of studentsList) {
                let data = {
                    id: student?.AdmissionId,
                    UserId: student?.id,
                    RollNo: student?.RollNo,
                };
                await api
                    .post(APIConstant.path.AddAdmissionDetail, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                        } else {
                            Toast.error({ message: res.message });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Toast.error({ message: 'Error update rollNo' });
                    });
            }

            Loader.hide();
            onCancel();
        } catch (err) {
            console.log(err);
            Loader.hide();
            Toast.error({ message: 'Error during update rollNo' });
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedList = Array.from(studentsList);
        const [movedItem] = reorderedList.splice(result.source.index, 1);
        reorderedList.splice(result.destination.index, 0, movedItem);

        const updatedList = reorderedList.map((student, index) => ({
            ...student,
            RollNo: index + 1,
        }));

        setStudentsList(updatedList);
    };

    return (
        <div className="p-fluid p-grid p-dir-col">
            <div className="p-col">
                <Button label="Assign" icon="pi pi-plus" className="p-button-primary p-ml-auto" onClick={() => setVisible(true)} />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="studentsTable">
                    {(provided) => (
                        <table
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="custom-table e-student-table"
                        >
                            <thead>
                                <tr className='e-student-list'>
                                    <th className='student-h' style={{width: '6%'}}>Sr No.</th>
                                    <th className='student-h' style={{width: '14%'}}>Student ID</th>
                                    <th className='student-h' style={{width: '15%'}}>Student Name</th>
                                    <th className='student-h' style={{width: '20%'}}>Email</th>
                                    <th className='student-h' style={{width: '15%'}}>Gender</th>
                                    <th className='student-h' style={{width: '15%'}}>House</th>
                                    <th className='student-h' style={{width: '15%'}}>Roll No.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentsList
                                    .filter((student) =>
                                        globalFilter
                                            ? student.Name.toLowerCase().includes(globalFilter.toLowerCase())
                                            : true
                                    )
                                    .map((student, index) => (
                                        <Draggable
                                            key={student?.StudentId}
                                            draggableId={student?.StudentId}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <tr className='e-student-list'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <td className='student-text' style={{width: '6%'}}>{index + 1}</td>
                                                    <td className='student-text' style={{width: '14%'}}>{student?.StudentId}</td>
                                                    <td className='student-text' style={{width: '15%'}}>{student?.Name}</td>
                                                    <td className='student-text' style={{width: '20%'}}>{student?.Email}</td>
                                                    <td className='student-text' style={{width: '15%'}}>{student?.Gender}</td>
                                                    <td className='student-text' style={{width: '15%'}}>{student?.House}</td>
                                                    <td className='student-text' style={{width: '15%'}}>{student?.RollNo}</td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="p-d-flex p-ai-center">
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-secondary p-mr-2" onClick={(e) => props.onCancel()} />
                <Button label="Save" className="p-button-primary p-mr-2" onClick={(e) => saveStudentRollNo()} />
            </div>

            <Dialog
                header="Assign"
                // className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="top"
            >
                <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <h3>House</h3>
                    <div className="p-field">
                        <label htmlFor="houseDropdown">Select House</label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '8px' }}>
                            <MultiSelect
                                id="houseDropdown"
                                value={selectedHouse}
                                options={houseList}
                                onChange={(e) => setSelectedHouse(e.value)}
                                placeholder="Select a House"
                                className="mr-2"
                                filter
                                display="chip"
                            />
                            <Checkbox
                                inputId="randomCheckbox"
                                checked={isRandom}
                                onChange={(e) => setIsRandom(e.checked)}
                            />
                            <label htmlFor="randomCheckbox" style={{ marginLeft: '8px' }}>Random</label>
                        </div>
                    </div>

                    <h3>Roll No.</h3>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Checkbox
                            inputId="byStudentID"
                            checked={byStudentID}
                            onChange={(e) => setByStudentID(e.checked)}
                        />
                        <label htmlFor="byStudentID">By Student ID</label>
                        <Checkbox
                            inputId="byNameWise"
                            checked={byNameWise}
                            onChange={(e) => setByNameWise(e.checked)}
                        />
                        <label htmlFor="byNameWise">By Name-wise</label>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button
                            label="RESET"
                            className="p-button-secondary"
                            onClick={resetForm}
                            style={{ backgroundColor: '#f0f0f0', color: '#000' }}
                        />
                        <Button
                            label="ASSIGN"
                            className="p-button-primary"
                            onClick={(e) => assignhouse()}
                        />
                    </div>
                </div>

            </Dialog>
        </div>
    );
};

export default StudentList;
