
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import { FloatLabel } from 'primereact/floatlabel';
import * as jsPDF from 'jspdf';
import ExportIdCardPDF from '../../Export/ExportIdCardPDF';
import { Dialog } from 'primereact/dialog';

const GenerateIdCardDialog = (props) => {
    const [perPage, setPerPage] = useState('');
    const [visible, setVisible] = useState(false);
    const [securityUserID, setSecurityUserID] = useState("");
    const [idCardTemplate, setIdCardTemplate] = useState('');
    const [medium, setMedium] = useState('');
    const [sections, setSections] = useState([]);
    const [classNameErrText, setClassNameErrText] = useState('');
    const [boardErrText, setBoardErrText] = useState('');
    const [mediumErrText, setMediumErrText] = useState('');
    const [classNameErr, setClassNameErr] = useState(false);
    const [boardErr, setBoardErr] = useState(false);
    const [mediumErr, setMediumErr] = useState(false);
    const [templateConfigurationData, setTemplateConfigurationData] = useState('');
    const pageSizeOptions = [
        { label: "A4 (210 × 297 mm)", value: "a4" },
        { label: "A5 (148 × 210 mm)", value: "a5" },
        { label: "Letter (8.5 × 11 in)", value: "letter" },
        { label: "Legal (8.5 × 14 in)", value: "legal" },
        { label: "Executive (7.25 × 10.5 in)", value: "executive" },
    ];
    const [pageSize, setPageSize] = useState('');
    const [idCardTemplateList, setIdCardTemplateList] = useState([]);
    const [pdfData, setPDfData] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetIdCardTemplateList(loginData?._id);
        GetStudentIdCardData(props?.ID, loginData?._id);
    }, []);

    const onCancel = () => {
        props?.onCancel();
        setVisible(false);
    }

    const GetStudentIdCardData = async (id, userId) => {
        try {
            let data = {
                ClassId: id,
                UserID: userId
            }
            await api.post(APIConstant.path.GetStudentIdCardData, data).then(async response => {
                let res = response;
                if (res.success) {
                    setPDfData(res?.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetIdCardTemplateList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.GetIdCardConfigurationList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setIdCardTemplateList(res.data.map(x => ({
                        ...x,
                        label: x?.TitleName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(idCardTemplate)) {
            formIsValid = false;
            setBoardErr(true);
            setBoardErrText("IdCard Template is required");
        } else {
            setBoardErr(false);
            setBoardErrText("");
        }

        if (CommonConfig.isEmpty(pageSize)) {
            formIsValid = false;
            setMediumErr(true);
            setMediumErrText("Page Size is required");
        } else {
            setMediumErr(false);
            setMediumErrText("");
        }
        return formIsValid;
    };

    const generatePDF = async () => {
        if (validate) {
            let content = document.getElementById('pdf-content');
            const doc = new jsPDF.jsPDF('landscape', 'px', 'a4');
            doc.setFont('Arial', 'normal');

            await doc.html(content, {
                margin: [20, 20, 20, 20],
            });

            doc.setProperties({
                title: 'IDCard',
                subject: 'Info about PDF',
                author: 'PDFAuthor',
                keywords: 'generated, javascript, web 2.0, ajax',
                creator: 'My Company'
            });

            // Generate data URL
            const pdfDataUri = doc.output('datauristring', {
                filename: 'IDCardPDF'
            });

            // Create a download link
            let filename = 'IDCardPDF';
            const downloadLink = document.createElement('a');
            downloadLink.href = pdfDataUri;
            downloadLink.download = filename + '.pdf';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    return (
        <div className="">
            <div className='p-grid'>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="p-w-100">
                        <label htmlFor="IdCardTemplate" className="mr-2">Id Card Template</label>
                        <div className="custom-inputs">
                            <Dropdown className='p-w-100' id="idCardTemplate" value={idCardTemplate} options={idCardTemplateList} onChange={(e) => {
                                setIdCardTemplate(e.value);
                                let filteredList = idCardTemplateList.filter(x => x.value === e.value)[0];
                                setTemplateConfigurationData(filteredList);
                            }} placeholder="Select Id Card Template" />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {boardErr ? boardErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="p-w-100">
                        <label htmlFor="pageSize" className="mr-2">Page Size</label>
                        <div className="custom-inputs">
                            <Dropdown className="p-mt-2 p-w-100" id="pageSize" value={pageSize} options={pageSizeOptions} onChange={(e) => setPageSize(e.value)} placeholder="Select Page Size" />
                        </div>
                        <span className="p-error block" style={{ color: 'red' }}>
                            {mediumErr ? mediumErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <label htmlFor="perPage">Per Page</label>
                    <div className="">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="perPage"
                                type="number"
                                placeholder="Per Page"
                                value={perPage}
                                autoFocus={true}
                                onChange={(e) => setPerPage(e.target.value)}
                            />
                        </FloatLabel>
                    </div>
                </div>
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-between p-px-5">
                <div>
                    <Button icon="pi pi-file-pdf" rounded className="e-pdf-btn p-m-0" tooltip={"Generate PDF"} tooltipOptions={{ className: 'bluegray-tooltip text-13', position: 'top' }} onClick={() => generatePDF()}></Button>
                </div>
                <div className='p-d-flex p-ai-center p-jc-end'>
                    <Button className='primary-btn1' onClick={() => onCancel()}> Cancel </Button>
                    <Button className='primary-btn p-m-0' label="Preview" severity="secondary" raised onClick={() => setVisible(true)} />
                </div>
            </div>
            <div className='p-d-none'>
                <div id="pdf-content" className='e-pdf-btn p-m-0'>
                    <ExportIdCardPDF {...props} fileName={'IdCardPDF'}
                        templateConfiguration={templateConfigurationData}
                        pdfData={pdfData} classSectionName={props?.classSectionName} />
                </div>
            </div>
            <Dialog
                header='View Id Card'
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
            >
                <ExportIdCardPDF {...props} fileName={'IdCardPDF'}
                    templateConfiguration={templateConfigurationData}
                    pdfData={pdfData} classSectionName={props?.classSectionName} />
                <div className="dialog-footer1 p-d-flex p-ai-center p-jc-center p-px-3 p-mt-4">
                    <Button icon="pi pi-file-pdf" rounded className="e-pdf-btn p-m-0" tooltip={"Generate PDF"} tooltipOptions={{ className: 'bluegray-tooltip text-13', position: 'top' }} onClick={() => generatePDF()}></Button>
                    <Button className='primary-btn1' onClick={() => onCancel()}> cancel </Button>
                </div>
            </Dialog >
        </div >
    );
};

export default GenerateIdCardDialog;
