import React from 'react';
import { apiBase } from '../../utils/config';
import moment from 'moment';
import UserImage from '../../assets/svgs/portlink-logo.svg';

const ExportIdCardPDF = (props) => {
    const { templateConfiguration, pdfData, classSectionName } = props;
    const pdfBlocks = [];
    pdfData?.map((y, idx) => {
        y?.map((x, idx) => {
            pdfBlocks.push(
                <div className='main-body'>
                    <div className="p-position-relative id-card-pdf">
                        <div className="id-card-main" style={{
                            "--dynamic-gradient-end": `${x?.HouseColor ? '#' + x?.HouseColor : ''}`,
                        }}>
                            <div className="card-parent">
                                <div className="info-box">
                                    {templateConfiguration?.HeaderSection?.includes('schoolLogo') ?
                                        <div className="header-logo">
                                            <img
                                                src={x?.SchoolPhotoUrl ? `${apiBase}/UserProfilePic/${x?.SchoolPhotoUrl}` : UserImage}
                                                // alt="School Logo"
                                                className="logo"
                                            />
                                        </div> : ''}
                                    {/* Information Box */}
                                    <div className="Info-box">
                                        <div className="background">
                                            <img src={templateConfiguration?.BackGroundImage ? `${apiBase}/TemplateBackGround/${templateConfiguration?.BackGroundImage}` : UserImage} />
                                        </div>
                                        {templateConfiguration?.BodySection?.includes('academicYear') ?
                                            <div className="current-year">
                                                <p className="year-text">2024</p>
                                                <p className="year-text">-</p>
                                                <p className="year-text">2025</p>
                                            </div> : ''}


                                        {/* Information Fields */}
                                        <div className="info-main">
                                            <div className="p-d-flex p-ai-center">
                                                {templateConfiguration?.BodySection?.includes('studentProfilePic') ?
                                                    <div className="profile-box" style={{
                                                        "border-radius": `${templateConfiguration?.ProfileStyle === 'round' ? '50%' : '6px'}`,
                                                    }}>
                                                        <img
                                                            src={x?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${x?.ProfilePhotoUrl}` : UserImage}
                                                            className="profile" style={{
                                                                "border-radius": `${templateConfiguration?.ProfileStyle === 'round' ? '50%' : '6px'}`,
                                                            }}
                                                        />
                                                    </div> : ''}
                                                {templateConfiguration?.BodySection?.includes('classSection') ?
                                                    <p className="standerd-text">{classSectionName}</p> : ''}
                                            </div>

                                            {templateConfiguration?.BodySection?.includes('studentName') ?
                                                <div className="name">
                                                    <p className="name-text">{x?.Name}</p>
                                                </div> : ''
                                            }
                                            {templateConfiguration?.BodySection?.includes('fatherName') ?
                                                <div className="inner-info">
                                                    <p className="inner-info-text">Father</p>
                                                    <p className="inner-info-subtext">
                                                        <span className="dot">:</span> {x?.FatherName}
                                                    </p>
                                                </div> : ''}
                                            {templateConfiguration?.BodySection?.includes('grNo') ?
                                                <div className="inner-info">
                                                    <p className="inner-info-text">G.R</p>
                                                    <p className="inner-info-subtext">
                                                        <span className="dot">:</span> {x?.GRNo}
                                                    </p>
                                                </div> : ''}
                                            {templateConfiguration?.BodySection?.includes('dob') ?
                                                <div className="inner-info">
                                                    <p className="inner-info-text">D.O.B.</p>
                                                    <p className="inner-info-subtext">
                                                        <span className="dot">:</span> {x?.DOB ? moment(x?.DOB).format("YYYY-MM-DD") : ''}
                                                    </p>
                                                </div> : ''}
                                            {templateConfiguration?.BodySection?.includes('fatherContactNo') ?
                                                <div className="inner-info">
                                                    <p className="inner-info-text">Contact</p>
                                                    <p className="inner-info-subtext">
                                                        <span className="dot">:</span> {x?.FatherPhoneNo}
                                                    </p>
                                                </div> : ''}
                                            {templateConfiguration?.BodySection?.includes('address') ?
                                                <div className="inner-info">
                                                    <p className="inner-info-text">Address</p>
                                                    <p className="inner-info-subtext font-size">
                                                        <span className="dot">:</span> {x?.Address}
                                                    </p>
                                                </div> : ''}
                                        </div>

                                        <div className="p-signature p-d-flex p-flex-column p-jc-end p-py-1">
                                            <img src={templateConfiguration?.SignImage ? `${apiBase}/TemplateSign/${templateConfiguration?.SignImage}` : UserImage} className='footer-sign' />
                                            <p className="inner-info-text p-w-md-auto p-mr-2">{templateConfiguration?.AuthorityTitle}</p>
                                        </div>
                                    </div>

                                    {templateConfiguration?.HeaderSection?.includes('schoolEmail') ?
                                        <div className="footer-text-box">
                                            <p className="footer-text">
                                                {`${x?.SchoolPhone}, ${x?.SchoolEmail}`}
                                            </p>
                                        </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    });
    return pdfBlocks;
};

export default ExportIdCardPDF;
