import React from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import '../../../assets/css/teacherMaster.css';
import userImage from '../../../assets/images/UserImage.png';
import { useNavigate } from 'react-router-dom';
import { apiBase } from '../../../utils/config';

const TeacherCard = ({ teacher }) => {
    const navigate  = useNavigate();
    return (
        <div className="teacher-card p-mt-4">
            <img src={ teacher?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${teacher?.ProfilePhotoUrl}` : teacher?.CreatedBy?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${teacher?.CreatedBy?.ProfilePhotoUrl}` : userImage} alt='Profile' className="teacher-image" />
            <h4 className='p-m-0'>{teacher?.Name}</h4>
            <p className='p-m-0'>{teacher?.Status}</p>
            <div className="subjects">
                {teacher?.subjects?.map(subject => (
                    <Tag key={subject} value={subject} />
                ))}
            </div>
            <div className="p-d-flex p-ai-center p-jc-center">
                <Button className='e-btn p-m-0' label="View"  onClick={() => navigate('/viewEdit-teacher-master',{ state: { userId: teacher?._id, isEdit: false }} )} />
                <Button className='e-sction-btn p-ml-2 p-mr-0' label="Edit" onClick={() => navigate('/viewEdit-teacher-master', { state: { userId: teacher?._id, isEdit:true } })} />
            </div>
        </div>
    );
}

export default TeacherCard;
